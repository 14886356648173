import Input, { InputTypes } from '@/components/atoms/legacy/Input';
import { DropdownMenuItemVariants } from '@/components/molecules/DropdownMenuItem';
import { formInputStyles } from '@/lib/constants';
import { ScComponent } from '@/types/interfaces';
import { ChevronDownIcon } from '@heroicons/react/24/outline';
import clsx from 'clsx';
import React, { useCallback, useEffect, useMemo } from 'react';
import DropdownMenu, {
  DropdownMenuGroupType,
  DropdownMenuOptionType,
  isDropdownMenuGroups,
} from '../DropdownMenu';

export interface FormSelectProps extends ScComponent {
  label?: string;
  disabled?: boolean;
  variant?: DropdownMenuItemVariants;
  options: DropdownMenuOptionType[] | DropdownMenuGroupType[];
  placeholder?: string;
  required?: boolean;
  value?: string[];
  onChange?: (val: string[]) => void;
  showCount?: boolean;
  inputClassName?: string;
  dropDownMenuClassName?: string;
  /** Flex direction of the label and the select. Default is `'col'`. */
  flexDirection?: 'row' | 'col';
  applyButtons?: boolean;
  clearMenu?: () => void;
  applyMenu?: () => void;
  autoCompleteInput?: boolean;
  setAutoCompleteInput?: (autoCompleteInput: boolean) => void;
  onPaste?: (e: React.ClipboardEvent<HTMLInputElement>) => void;
  invalid?: boolean;
  step?: number;
  float?: number;
}

const FormSelect: React.FC<FormSelectProps> = ({
  className = '',
  inputClassName = '',
  dropDownMenuClassName = '',
  label,
  disabled = false,
  variant = DropdownMenuItemVariants.MultiSelect,
  options,
  placeholder = '',
  value = [],
  onChange = () => {},
  showCount = true,
  flexDirection = 'col',
  clearMenu,
  applyMenu,
  autoCompleteInput = false,
  setAutoCompleteInput = () => {},
  invalid = false,
  step = 1,
  float = 0,
  onPaste,
  ...props
}) => {
  const [showOptions, setShowOptions] = React.useState(false);
  const labelRef = React.useRef<HTMLElement>(null);
  const autoCompleteRef = React.useRef<HTMLElement>(null);
  const buttonRef = React.useRef<HTMLButtonElement>(null);
  const [filteredOptions, setFilteredOptions] = React.useState<
    DropdownMenuOptionType[] | DropdownMenuGroupType[]
  >(options);
  const [inputValue, setInputValue] = React.useState<string>('');

  const noOptions = useMemo(() => {
    return isDropdownMenuGroups(filteredOptions)
      ? filteredOptions.flatMap((group) => group.menuItems).length === 0
      : filteredOptions.length === 0;
  }, [filteredOptions]);

  useEffect(() => {
    setFilteredOptions(options);
  }, [options]);

  const handleApplyMenu = useCallback(() => {
    if (applyMenu) {
      applyMenu();
    }
    setInputValue('');
    setShowOptions(false);
  }, [applyMenu]);

  React.useEffect(() => {
    const clickOutside = (evt: MouseEvent) => {
      if (!evt.target || !labelRef.current) {
        return;
      }
      if (
        buttonRef.current === evt.target ||
        buttonRef.current?.contains(evt.target as Node)
      ) {
        setShowOptions(!showOptions);
      } else if (!labelRef.current.contains(evt.target as Node)) {
        setShowOptions(false);
        setInputValue('');
        setFilteredOptions(options);
      }
    };
    const handlePressEnter = (evt: KeyboardEvent) => {
      if (evt.key === 'Enter' && showOptions) {
        evt.preventDefault();
        handleApplyMenu();
      }
    };

    document.addEventListener('keydown', handlePressEnter);
    document.addEventListener('click', clickOutside);
    return () => {
      document.removeEventListener('click', clickOutside);
      document.removeEventListener('keydown', handlePressEnter);
    };
  }, [showOptions, options, handleApplyMenu, filteredOptions]);

  useEffect(() => {
    if (showOptions && autoCompleteInput) {
      autoCompleteRef.current?.querySelector('input')?.focus();
    }
  }, [showOptions, autoCompleteInput, value]);

  useEffect(() => {
    if (!showOptions) {
      setAutoCompleteInput(true);
    }
  }, [showOptions, setAutoCompleteInput]);

  const onInputChange = useCallback(
    (e: string) => {
      setInputValue(e);
      if (isDropdownMenuGroups(options)) {
        const newFilteredOptions: DropdownMenuGroupType[] = options.map(
          (category) => {
            return {
              category: category.category,
              index: category.index,
              menuItems: category.menuItems.filter(
                (o: DropdownMenuOptionType) => {
                  return o.value.toLowerCase().startsWith(e.toLowerCase());
                }
              ),
            };
          }
        );
        setFilteredOptions(
          newFilteredOptions.length > 0 ? newFilteredOptions : options
        );
      } else {
        const newFilteredOptions: DropdownMenuOptionType[] = options.filter(
          (o: DropdownMenuOptionType) =>
            o.value.toLowerCase() === e.toLowerCase()
        );
        setFilteredOptions(
          newFilteredOptions.length > 0 ? newFilteredOptions : options
        );
      }
    },
    [options, setFilteredOptions]
  );

  return (
    <section className={`relative block ${className}`} ref={labelRef}>
      <div className={`flex flex-${flexDirection} h-full`}>
        {label && (
          <label className="text-body2Regular text-neutral-800">
            <span className="mb-s block">{label}</span>
          </label>
        )}
        {(!autoCompleteInput || !showOptions) && (
          <button
            ref={buttonRef}
            className={`input ${formInputStyles} justify-between text-left ${
              showOptions ? '' : 'focus:border-neutral-400'
            } disabled:cursor-not-allowed disabled:opacity-50 peer-invalid:border-red-medium peer-invalid:bg-red-100 ${inputClassName} `}
            disabled={disabled}
          >
            <span
              className={`my-0 w-0 flex-1 overflow-hidden text-ellipsis whitespace-nowrap py-0`}
            >
              {value
                .map((val) => {
                  const flatOptions = isDropdownMenuGroups(options)
                    ? options.map((group) => group.menuItems).flat()
                    : options;
                  return flatOptions.find((option) => option.value === val)
                    ?.text;
                })
                .join(', ') || placeholder}
            </span>
            <ChevronDownIcon
              className={clsx(
                'size-l stroke-2 text-neutral-600 transition-transform',
                showOptions && 'rotate-180'
              )}
            />
          </button>
        )}
        {autoCompleteInput && (
          <section
            className={`${showOptions ? 'flex' : 'hidden'}`}
            ref={autoCompleteRef}
          >
            <Input
              className={`${formInputStyles} ${inputClassName} ${noOptions || invalid ? '!border-red-medium' : ''} h-full`}
              placeholder={placeholder}
              onChange={(e) => onInputChange(e)}
              onPaste={onPaste}
              float={float}
              type={InputTypes.Text}
              step={step}
              onClick={() => {}}
              value={inputValue}
              {...props}
            />
          </section>
        )}
      </div>
      <DropdownMenu
        className={`absolute z-20 ${
          showOptions ? '' : 'hidden'
        } left-0 right-0 top-full translate-y-[4px] ${dropDownMenuClassName}`}
        variant={variant}
        value={value}
        options={filteredOptions}
        noOptions={noOptions}
        showCount={showCount}
        onChange={(val) => {
          if (variant === DropdownMenuItemVariants.SingleSelect) {
            setShowOptions(false);
          }
          if (autoCompleteInput) {
            setAutoCompleteInput(false);
          }
          onChange(val);
        }}
        clearAll={clearMenu}
        apply={() => {
          onInputChange('');
          handleApplyMenu();
        }}
      />
    </section>
  );
};

export default FormSelect;
